import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, mergeProps as _mergeProps, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-notifications-wrapper" }
const _hoisted_2 = { class: "v-notifications-header" }
const _hoisted_3 = { class: "v-notifications-title" }
const _hoisted_4 = { class: "v-emails" }

import { BaseListEntry } from '@/_shared/components';
import emailIcon from '@/_shared/assets/icons/email.svg';
import useFetchOrganisationRoles from '@/notifications/composables/notifications';
import {
  computed, ComputedRef, ref, watch,
} from 'vue';
import { NestedLink } from '@/_shared/types/baseListEntry';
import { RouterLink, useRoute } from 'vue-router';
import vTab from '@/_shared/directives/TabHandler';
import TeleportWrapper from '@/_shared//components/general/TeleportWrapper.vue';
import { IconColor } from '@/_shared/types/iconColor';
import use from '@/_shared/compositionApi';
import BaseIcon from '../../_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsLeftPanel',
  emits: ['hideLeftPanelOnMobile'],
  setup(__props, { emit: __emit }) {

const route = useRoute();
const { translate } = use.helpers();
const organisationRoles = useFetchOrganisationRoles();
const defaultChildrenProps = {
  rightIcon: 'chevron-right',
  withIcon: false,
  rightIconSize: 'sm',
  fontSize: '14px',
  padding: '7px 10px',
};

const emit = __emit;

watch(
  () => route.name,
  (routeName) => {
    emit('hideLeftPanelOnMobile', routeName === 'notifications');
  },
  { immediate: true },
);

const nestedLink: ComputedRef<NestedLink[]> = computed(() => {
  const roles: NestedLink[] = [
    {
      props: {
        title: 'Superuser',
        ...defaultChildrenProps,
      },
      to: {
        name: 'notifications', // name of the route to be added here
      },
    },
    ...organisationRoles.value.map((role) => ({
      props: {
        title: role.name,
        ...defaultChildrenProps,
      },
      to: {
        name: 'notifications', // name of the route to be added here
      },
    })),
  ];
  return roles;
});

const listEntryChildren = computed(() => (expandCategory.value ? nestedLink.value : []));
const expandCategory = ref(true);
const toggleExpandCategory = () => {
  expandCategory.value = !expandCategory.value;
};
const chevronName = computed(() => (expandCategory.value ? 'chevron-down' : 'chevron-right'));

const clickedEntry = ref<string | null>(null);
const rightIconColor = ref<IconColor>('black');

const invertColour = (entry: string) => {
  clickedEntry.value = entry;
  rightIconColor.value = 'white';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TeleportWrapper, {
    to: "#lp-container",
    class: "v-notifications-left-panel"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(translate)('roles.role_settings')), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives((_openBlock(), _createBlock(_unref(BaseListEntry), {
            title: _unref(translate)('notifications.emails'),
            icon: _unref(emailIcon),
            class: "v-email-entry",
            "font-size": "18px",
            "with-icon": "",
            active: "",
            padding: "10px 15px",
            "blank-icon": "",
            "background-icon-colour": "transparent",
            children: listEntryChildren.value
          }, {
            headerRightCol: _withCtx(() => [
              _createVNode(BaseIcon, {
                name: chevronName.value,
                "font-size": "16px",
                noHover: "",
                compact: "",
                "foreground-color": "black",
                "foreground-hover-color": "grey",
                onClick: toggleExpandCategory
              }, null, 8, ["name"])
            ]),
            childEntry: _withCtx((slotProps) => [
              _createVNode(_unref(RouterLink), {
                to: slotProps.to
              }, {
                default: _withCtx(({isActive}) => [
                  _withDirectives(_createVNode(_unref(BaseListEntry), _mergeProps({
                    class: ['v-organisation-role-entry', { 'clicked': clickedEntry.value === slotProps.props.title }],
                    onClick: () => invertColour(String(slotProps.props.title))
                  }, { ...slotProps.props, active: !!isActive, rightIconColor: clickedEntry.value === slotProps.props.title ? rightIconColor.value : 'black' }), null, 16, ["class", "onClick"]), [
                    [_unref(vTab)]
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          }, 8, ["title", "icon", "children"])), [
            [_unref(vTab)]
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})